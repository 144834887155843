import { deepFreeze } from "@/utils/structure";

export const HOME_OG_IMAGE_URL =
  "https://cdn.sanity.io/images/bm4duuk0/production/d79a1f8ce04ae86ccc1ecdae494598c96538683b-1200x630.png";

// taking from https://stripe.com/docs/api/payment_methods/object#payment_method_object-type
export const PAYMENT_METHODS = deepFreeze({
  PAYNOW: "Stripe PayNow",
  CARD: "Stripe Card",
});
export const CARD_SURCHARGE_PERCENTAGE = 3;

export const STANDARD_SHIPPING_CONSUMER_SURCHARGE_SGD = 12.5;
export const RURAL_SURCHARGE_SGD = 33.6;
export const DIRECT_SIGNATURE_CHARGE_SGD = 7;
export const INSURANCE_CHARGE_PERCENTAGE = 2.4;

export const STANDARD_SHIPPING_ENABLED =
  process.env.NEXT_PUBLIC_STANDARD_SHIPPING_ENABLED === "1" || false;
export const ECOMMERCE_STANDARD_SHIPPING_ENABLED =
  process.env.NEXT_PUBLIC_ECOMMERCE_STANDARD_SHIPPING_ENABLED === "1" || false;
export const SHIPPING_METHODS = deepFreeze({
  STANDARD: {
    label: "Standard Shipping",
    value: "STANDARD",
    duration: "12-15 working days",
  },
  EXPRESS: {
    label: "Express Shipping",
    value: "EXPRESS",
    duration: "2-5 working days",
  },
});

export const BULK_UPLOAD_ECOMMERCE_ENABLED =
  process.env.NEXT_PUBLIC_BULK_UPLOAD_ECOMMERCE_ENABLED === "1" || false;

export const USER_READ_DATA_FROM_POSTGRESQL =
  process.env.USER_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const SHIPMENT_READ_DATA_FROM_POSTGRESQL =
  process.env.SHIPMENT_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const PRICING_READ_DATA_FROM_POSTGRESQL =
  process.env.PRICING_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const PAYMENT_READ_DATA_FROM_POSTGRESQL =
  process.env.PAYMENT_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const WHATSAPP_BUSINESS_READ_DATA_FROM_POSTGRESQL =
  process.env.WHATSAPP_BUSINESS_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const TRACKING_READ_DATA_FROM_POSTGRESQL =
  process.env.TRACKING_READ_DATA_FROM_POSTGRESQL === "1" || false;
export const GIFT_CARD_READ_DATA_FROM_POSTGRESQL =
  process.env.GIFT_CARD_READ_DATA_FROM_POSTGRESQL === "1" || false;

export const DEFAULT_COUNTRY_CALL_CODE = "SG/+65";

export const OPTIMIZE_COOKIE_NAME = "ab-optimize";

export const VALENTINES_BOUQUET_PRICE = 42;

export const EXCLUDED_COUNTRIES = [
  "RU",
  "UA",
  "BY",
  "AD",
  "SG",
  "VE", // FedEx unable to have a fixed set of cities for Venezuela
];

export const ITEM_CATEGORIES = [
  { value: "mobiles", text: "Mobile Phones" },
  { value: "tablets", text: "Mobile Tablets" },
  { value: "computers", text: "Computers" },
  { value: "cameras", text: "Cameras" },
  { value: "accessories", text: "Cables" },
  // { value: "accessoriesbattery", text: "Accessories (Battery)" },
  { value: "headphones", text: "Headphones & Earphones" },
  { value: "healthbeauty", text: "Beauty & Skincare" },
  { value: "fashion", text: "Clothes" },
  { value: "watches", text: "Watches" },
  { value: "jewelry", text: "Jewelry" },
  { value: "petsaccessories", text: "Pets Accessories" },
  // { value: "dryfoodsupplements", text: "Dry Food & Supplements" }
  { value: "dryfood", text: "Dry Foods" },
  { value: "supplements", text: "Supplements" },
  { value: "homeappliances", text: "Home Appliances" },
  { value: "homedecor", text: "Paintings" },
  { value: "toys", text: "Toys" },
  { value: "sports", text: "Exercise Equipment" },
  { value: "handbags", text: "Handbags" },
  { value: "audiovideo", text: "Audio Video" },
  { value: "documents", text: "Documents & Photos" },
  { value: "gaming", text: "Games & Consoles" },
  // { value: "bookscollectibles", text: "Books" }
  { value: "books", text: "Books" },
];

export const HS_CODE_MAP = {
  mobiles: "851712",
  tablets: "847130",
  computers: "847130",
  cameras: "900659",
  accessories: "854420",
  headphones: "851830",
  healthbeauty: "330410",
  fashion: "630900",
  watches: "910211",
  jewelry: "711790",
  petsaccessories: "420100",
  dryfood: "190590",
  supplements: "293621",
  homeappliances: "851629",
  homedecor: "590190",
  toys: "950300",
  sports: "950631", // refers to golf clubs
  handbags: "420221",
  audiovideo: "852349",
  documents: "491199",
  gaming: "950450",
  books: "490199",
};

export const GIFT_CARD_CONSTANTS = {
  MIN_AMOUNT: 20,
  MAX_NAME_LENGTH: 50,
  MAX_MESSAGE_LENGTH: 200,
  PRESET_AMOUNTS: [50, 100, 300],
  MONTHS_TO_EXPIRY: 12,
};
